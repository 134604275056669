<template>
    <div
        v-if="blok"
        ref="multiColumn"
        v-editable="blok"
        class="multi-column-layout"
        data-test="multi-column-layout"
        :class="layoutClasses"
        :style="layoutStyles"
    >
        <NuxtImg
            v-if="props.blok.BackgroundImage?.filename"
            :src="props.blok.BackgroundImage?.filename"
            :alt="props.blok.BackgroundImage?.alt"
            provider="storyblok"
            sizes="sm:200vw md:150vw lg:100vw xl:1920px"
            format="webp"
            class="bg-image"
            densities="x1 x2"
            loading="lazy"
        />
        <div class="mx-auto content-container" :class="{ container: !blok.fullWidth }">
            <div class="grid-wrapper" :class="{ breakpoints: blok.BreakPointsItemsInRow }" :style="gridWrapperStyles">
                <template v-for="slot in blok.slots" :key="slot._uid">
                    <div v-if="slot" :style="[getSlotStyles(slot)]" class="flex slot" :class="getSlotClasses(slot)">
                        <template v-for="content in slot.content" :key="content._uid">
                            <Component :is="content.component" :blok="content" class="w-full" />
                        </template>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { IMultiColumnsLayout, ISlot } from '~ui/types/components/MultiColumnsLayout';

const props: any = defineProps<{ blok: IMultiColumnsLayout }>();

const minHeight = ref(0);
const multiColumn: Ref<HTMLElement | null> = ref(null);

const layoutStyles = computed(() => {
    const hasGradient = props.blok.backgroundGradient1?.color && props.blok.backgroundGradient2?.color;
    const gradient = `linear-gradient(180deg, ${props.blok.backgroundGradient1?.color} 0%, ${props.blok.backgroundGradient2?.color} 100%)`;
    const backgroundColor = props.blok.BackgroundColor?.color;

    return {
        background: hasGradient ? gradient : backgroundColor,
        paddingTop: props.blok.paddingTop ? `${props.blok.paddingTop}px` : null,
        paddingBottom: props.blok.paddingBottom ? `${props.blok.paddingBottom}px` : null,
    };
});

const layoutClasses = computed(() => ({
    dividers: props.blok.ShowDividers,
    'hide-on-desktop': props.blok.displayOption === 'only-mobile',
    'hide-on-mobile': props.blok.displayOption === 'only-desktop',
    'has-background-image': props.blok.BackgroundImage?.filename,
}));

const gridWrapperStyles = computed(() => {
    return {
        '--items-in-row-desktop': props.blok.Desktop || 4,
        '--items-in-row-desktop-small': props.blok.SmallDesktop || 3,
        '--items-in-row-tablet': props.blok.Tablet || 2,
        '--items-in-row-mobile': props.blok.MobileBreakpointItems || 1,
        '--multicolumn-row-gap': props.blok.rowGap || null,
        '--column-gap': props.blok.ColumnGap ? `${props.blok.ColumnGap}px` : null,
        '--min-image-wrapper-height': `${minHeight.value}px`,
    };
});

const getSlotStyles = (slot: ISlot) => {
    return `
         ${slot.columns && !props.blok.BreakPointsItemsInRow ? `grid-column: span ${slot.columns} / span ${slot.columns}` : ''};
         ${slot.PaddingTop ? `padding-top: ${slot.PaddingTop}px` : ''};
         ${slot.PaddingBottom ? `padding-bottom: ${slot.PaddingBottom}px` : ''};
         ${slot.PaddingLeft ? `padding-left: ${slot.PaddingLeft}px` : ''};
         ${slot.PaddingRight ? `padding-right: ${slot.PaddingRight}px` : ''};
         ${slot.BackgroundColor?.color ? `background-color: ${slot.BackgroundColor?.color}` : ''};
         ${slot.direction ? `--flex-direction: ${slot.direction}` : null};
         ${slot.directionMobile ? `--flex-direction-mobile: ${slot.directionMobile}` : `--flex-direction-mobile: ${slot.direction}`};`;
};
const getSlotClasses = (slot: ISlot) => {
    return [
        `justify-${slot.text_align} items-${slot.align_items}`,
        `columns-${slot.columns}`,
        { 'mob-hidden': slot.hide_on_mobile },
    ];
};
const updateMinHeight = () => {
    if (multiColumn.value) {
        const images = multiColumn.value.querySelectorAll('.simple-image img');
        let max = 0;

        images.forEach((img) => {
            const height = img.clientHeight;
            if (height > max) {
                max = height;
            }
        });

        minHeight.value = max;
    }
};

onMounted(() => {
    if (multiColumn.value) {
        const images = multiColumn.value?.querySelectorAll('.simple-image img');
        images.forEach((img) => {
            const observer = new ResizeObserver(() => {
                updateMinHeight();
            });
            observer.observe(img);
        });
    }
});
</script>

<style scoped lang="postcss">
.container {
    @apply mx-auto px-4;
}

.multi-column-layout {
    @apply relative py-6 max-md:py-4;

    .bg-image {
        @apply absolute top-0 left-0 w-full h-full object-cover object-center;
    }

    .content-container {
        @apply relative z-[1];
    }

    .grid-wrapper {
        @apply grid grid-cols-1 md:grid-cols-12 gap-y-[var(--multicolumn-row-gap)] gap-x-[var(--column-gap)];

        &.breakpoints {
            @apply xl:grid-cols-[repeat(var(--items-in-row-desktop),1fr)]
            lg:grid-cols-[repeat(var(--items-in-row-desktop-small),1fr)]
            md:grid-cols-[repeat(var(--items-in-row-tablet),1fr)]
            grid-cols-[repeat(var(--items-in-row-mobile),1fr)];
            grid-template-rows: 1fr;
        }
    }

    &.dividers {
        border-top: 1px solid #e5e7eb;
        border-bottom: 1px solid #e5e7eb;
    }

    &.hide-on-mobile {
        @apply !hidden md:!block;
    }

    &.hide-on-desktop {
        @apply !block md:!hidden;
    }
}

.multi-column-layout {
    .mob-hidden,
    .hide-on-mobile {
        @apply hidden md:flex;
    }

    &.has-background-image {
        :deep(h2) {
            @media screen and (max-width: 768px) {
                text-shadow: #fff 1px 0 10px;
            }
        }
    }
}

.slot {
    @apply gap-4;
    flex-direction: var(--flex-direction);
    @media screen and (max-width: 768px) {
        flex-direction: var(--flex-direction-mobile);
    }
}
</style>
